import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../components/layouts/secondary-landing";
import Breadcrumb from "../../components/breadcrumb/breadcrumb";

import HeroChevron from "../../components/hero/hero-chevron";
import SEO from "../../components/seo/seo";
import List from "../../components/custom-widgets/list";
import Icon from "../../components/custom-widgets/icon";

import LinearGradientStyle from "../../components/best-banks/best-banks.module.scss";

// Helpers
import getHeroImgVariables from "../../helpers/getHeroImgVariables";

const FreshStartSecondChanceAccount = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/fresh-start/hero-fresh-start-03242023-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(relativePath: { eq: "hero/personal-banking/fresh-start/hero-fresh-start-03242023-XL.jpg" }) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(relativePath: { eq: "hero/personal-banking/fresh-start/hero-fresh-start-03242023-LG.jpg" }) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(relativePath: { eq: "hero/personal-banking/fresh-start/hero-fresh-start-03242023-MD.jpg" }) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(relativePath: { eq: "hero/personal-banking/fresh-start/hero-fresh-start-03242023-SM.jpg" }) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(relativePath: { eq: "hero/personal-banking/fresh-start/hero-fresh-start-03242023-XS.jpg" }) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/fresh-start/hero-fresh-start-03242023-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const breadcrumbData = [
    {
      id: 1,
      url: "/personal-banking",
      title: "Personal Banking"
    },
    {
      id: 2,
      active: true,
      title: "Fresh Start Account"
    }
  ];

  const seoData = {
    title: "Fresh Start Account - Your Second Chance at Banking",
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        content: "Fresh Start Account - Your Second Chance at Banking"
      },
      {
        name: "description",
        content:
          "Start fresh with WaFd Bank's second chance bank account. If you've been denied a bank account, our Fresh Start account can help you get back on track."
      },
      {
        property: "og:title",
        content: "Fresh Start Account - Your Second Chance at Banking"
      },
      {
        property: "og:description",
        content:
          "Start fresh with WaFd Bank's second chance bank account. If you've been denied a bank account, our Fresh Start account can help you get back on track."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/personal-banking/fresh-start-second-chance-account"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/og-fresh-start-03242023.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "fresh-start-page-hero",
    ...getHeroImgVariables(imgData),
    altText: "Happy couple dancing together in the kitchen.",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Get a Second Chance with WaFd Bank"
          }
        },
        {
          id: 2,
          button: {
            id: "fresh-start-hero-cta-1",
            text: "Open a Fresh Start Account",
            url: "/open-an-account",
            class: "btn-white btn-long"
          }
        }
      ]
    }
  };

  const freshStartListItems = {
    accountName: "fresh-start-list",
    items: [
      {
        id: 1,
        text: "Only $25 initial deposit to open"
      },
      {
        id: 2,
        text: "$4 monthly maintenance fee"
      },
      {
        id: 3,
        text: "Access to online and mobile banking"
      },
      {
        id: 4,
        text: "Person-to-person payments"
      },
      {
        id: 5,
        text: "No minimum balance requirements"
      },
      {
        id: 6,
        text: "No extra debit card restrictions"
      },
      {
        id: 7,
        text: "No overdraft or Nonsufficient funds fees"
      },
      {
        id: 8,
        text: "After building a positive account history, upgrade to a traditional checking account"
      }
    ]
  };

  const FreshStartDisclaimerText = () => (
    <span id="fresh-start-disclaimer">
      * Fresh Start account is available to individuals who are unable to open a traditional checking account due to a
      negative ChexSystems&reg; report, if the account(s) reported to ChexSystems are not classified as fraud and have
      been paid in full. Customers must maintain their Fresh Start account in good standing. An account will be deemed
      "not in good standing" if any combination of two (2) or more ACH or debit card transactions are presented that
      would cause the account to become overdrawn if paid. If this occurs, the debit card may be deactivated, and WaFd
      Bank may deem the account must be closed. Customers who maintain their account in "good standing" will be eligible
      to access other products and services shortly after the 12-month anniversary of the account opening date. This
      account is excluded from any "new checking" promotion that may be occurring at the time of account opening.
    </span>
  );

  return (
    <SecondaryLanding footerBorder={false}>
      <HeroChevron {...heroChevronData} />
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Fresh Start Account</h1>
        <h3>
          If you've been denied a bank account in the past, Fresh Start* helps you get back into the banking system with
          a low, consistent monthly maintenance fee. Say goodbye to check cashing fees and high transaction fees so you
          can start saving and fast track your financial goals.
        </h3>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 order-1 order-md-0">
              <h3 className="font-weight-semi-bold text-success">Get everything you need in one place</h3>
              <List {...freshStartListItems} />
              <div className="my-4">
                <Link className="btn btn-primary" to="/open-an-account" id="fresh-start-open-account-cta">
                  Open a Fresh Start Account
                </Link>
              </div>
            </div>
            <div className="col-md-6 my-3">
              <StaticImage
                src="../../images/thumbnail-fresh-start-03232023-730.jpg"
                alt="Mother and daughter smiling at each-other while painting."
                placeholder="blurred"
                quality="100"
                loading="eager"
              />
            </div>
          </div>
          <p className="text-sm text-gray-70 d-none d-md-block" style={{ columnCount: "2" }}>
            <FreshStartDisclaimerText />
          </p>
        </div>
      </section>
      <section>
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3">
              <StaticImage
                src="../../images/thumbnail-start-saving-080124.jpg"
                alt="Young man relaxing while working on his laptop at home."
                placeholder="blurred"
                quality="100"
                loading="eager"
              />
            </div>
            <div className="col-md-6">
              <h3 className="text-success font-weight-bold">Start Savings</h3>
              <p>
                With a higher interest rate than a traditional savings account, Start Savings is designed to help you
                save and earn more for doing it. To open, just have any WaFd Checking account (including Fresh Start)
                and start paying your future self.
              </p>
              <ul>
                <li>Only $25 initial deposit</li>
                <li>No minimum balance requirements</li>
                <li>No monthly service charge</li>
                <li>
                  <Link id="fresh-start-interest-rates-link" to="/personal-banking/interest-rates">
                    Variable blended rate
                  </Link>{" "}
                  (interest rate is different depending on how much money you have in the account)
                </li>
              </ul>
              <Link id="fresh-start-open-an-account-link" to="/open-an-account" className="btn btn-primary">
                Open a Start Savings Account
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="bg-light">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mb-3 order-2">
              <StaticImage
                src="../../images/thumbnail-fresh-start-blog-03272023-730.jpg"
                alt="Man relaxing at home, drinking coffee and using a smartphone."
                placeholder="blurred"
                quality="100"
                loading="eager"
              />
            </div>
            <div className="col-md-6 order-1">
              <h3 className="text-success font-weight-bold">What to Do If You Can't Open a Bank Account</h3>
              <p>
                Frustrated that you can't open a bank account? Learn why this happened and what you can do to open a
                Fresh Start account at WaFd Bank.
              </p>
              <p>
                <Link className="text-decoration-none" to="/blog/banking-101" id="banking-101-blogs-category-link">
                  See all Banking 101 Articles <Icon name="arrow-right" class="ml-1" />
                </Link>
              </p>
              <p>
                <Link
                  id="fresh-start-blog-link"
                  to="/blog/banking-101/why-cant-open-bank-account-what-to-do"
                  className="btn btn-link"
                >
                  Read Fresh Start Article
                </Link>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="container d-block d-md-none">
        <p className="text-sm text-gray-60">
          <FreshStartDisclaimerText />
        </p>
      </section>
      <section id="bankon-section" className={`${LinearGradientStyle.bestBanksBg}`}>
        <div className="container">
          <div className="row">
            <div className="col-auto mb-3">
              <img
                style={{ width: "159px" }}
                src="../../images/bankon-logo-03312023.svg"
                alt="WaFd Bank Fresh Start BankOn Certified."
              />
            </div>
            <div className="col-sm-7 col-md-8 col-lg-9">
              <h3 className="font-weight-semi-bold">BankOn Certified</h3>
              <p>WaFd Bank's Fresh Start Account is BankOn Certified.</p>
            </div>
          </div>
        </div>
      </section>
    </SecondaryLanding>
  );
};
export default FreshStartSecondChanceAccount;

